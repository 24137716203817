<template>
  <b-modal :id="modalId" :title="name" :centered=true :hide-footer="true">
    <template #modal-title>
      <span class="h4 mr-2">{{ name }}</span>
      <span v-if="currentUser.is_admin" class="h6">
        <b-link v-b-modal="'versions-modal-' + token.uuid">
          <span>Versions</span>
        </b-link>

        <versions-modal
          :title="'Token ' + token.name"
          :uuid="token.uuid"
          :versions="token.versions"
        />
      </span>

      <span v-if="isMetricsActive" class="h6 ml-2">
        <router-link :to="metricsUrl">Metrics</router-link>
      </span>
    </template>

    <b-form v-on:submit.prevent="updateToken">
      <b-form-group label="Display Name">
        <b-form-input required v-model="name" :disabled="!currentUser.is_admin" />
      </b-form-group>

      <div class="d-flex justify-content-between">
        <div class="flex-fill mr-2">
          <b-form-group label="Auth Username">
            <b-form-input required v-model="authUsername" :disabled="!currentUser.is_admin" />
          </b-form-group>
        </div>

        <b-button class="input-height" @click="$_copyToClipboard(authUsername)">
          Copy
        </b-button>
      </div>

      <div class="d-flex justify-content-between">
        <div class="flex-fill mr-2">
          <b-form-group label="Value">
            <b-form-input v-model="tokenValue" :disabled="!currentUser.is_admin" />
          </b-form-group>
        </div>

        <b-button class="input-height" @click="$_copyToClipboard(tokenValue)">
          Copy
        </b-button>
      </div>

      <b-form-group label="Rate tier">
        <b-form-select
          v-model="rateTier"
          :options="rateTierDropdownOptions"
          :disabled="!currentUser.is_admin"
        />
      </b-form-group>

      <div v-if="currentUser.is_admin || subscription.cascade_marketplace" class="mb-3">
        <b-form-checkbox size="lg" v-model="cascadeMarketplace" :disabled="!currentUser.is_admin">
          Cascade marketplace
        </b-form-checkbox>
      </div>

      <div v-if="currentUser.is_admin" class="border-top">
        <div class="d-flex justify-content-between mt-3">
          <div class="form-group text-right">
            <input type="submit" value="Save" class="btn btn-primary">
          </div>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<style>
.input-height {
  height: calc(1.5em + 0.625rem + 2px);
  top: calc(1.5em + 0.4rem);
  position: relative;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import http from '@/services/http';
import versionsModal from '../../globals/versions-modal';

import copyToClipboard from '@/mixins/copy-to-clipboard';

export default {
  name: 'EditTokenModal',

  components: {
    versionsModal
  },

  props: {
    token: {
      type: Object,
      required: true
    },

    subscription: {
      type: Object,
      required: true
    },

    supportedRateTiers: {
      type: Array,
      default: () => ([])
    },

    isAccountAndSubscriptionActive: {
      type: Boolean,
      required: false,
      default: () => true
    },
  },

  watch: {
    token: {
      immediate: true,
      deep: true,
      handler: 'loadToken'
    }
  },

  data() {
    return {
      tokenLoaded: false,
      name: null,
      authUsername: null,
      tokenValue: null,
      rateTier: null,
      cascadeMarketplace: null,
    }
  },

  mixins: [copyToClipboard],

  computed: {
    ...mapGetters('sessions', [
      'currentUser'
    ]),
    ...mapState({
      rateTiers: state => state.rateTiers.rateTiers
    }),

    rateTierDropdownOptions() {
      return this.supportedRateTiers.map(rt => (rt.name));
    },

    rateTierUuid() {
      return '';
    },

    modalId() {
      return 'token-modal-' + this.token.uuid
    },

    metricsUrl() {
      return {
        path: `/accounts/${this.subscription.account.uuid}/metrics`,
        query: {
          selected_subscription_uuid: this.subscription.uuid,
          selected_token_uuid: this.token.uuid
        }
      }
    },

    isMetricsActive() {
      if (this.currentUser?.is_admin) {
        return true;
      } else {
        return this.subscription?.account.metrics_enabled && this.isAccountAndSubscriptionActive && this.token.is_active;
      }
    },
  },

  methods: {
    loadToken() {
      if (!this.tokenLoaded) {
        this.name = this.token.name;
        this.authUsername = this.token.auth_username;
        this.tokenValue = this.token.value;
        this.rateTier = this.token.rate_tier;
        this.cascadeMarketplace = this.token.cascade_marketplace;

        this.tokenLoaded = true;
      }
    },

    async updateToken() {
      let params = {
        name: this.name,
        rate_tier: this.rateTier
      }

      if (this.currentUser.is_admin) {
        params.value = this.tokenValue;
        params.auth_username = this.authUsername;
        params.cascade_marketplace = this.cascadeMarketplace;
      }

      try {
        const response = await http.put(`tokens/${this.token.uuid}`, { token: params });
        const updatedToken = response.data.token;

        this.$bvToast.toast('You successfully updated the token', {
          title: 'Updated',
          variant: 'default'
        });

        this.$emit('token-updated', updatedToken);

        this.$bvModal.hide(this.modalId);
      }

      catch (error) {
        console.log(error);
      }
    },
  }
}
</script>
