<template>
  <div v-if="hasActiveTokens">
    <div class="mt-4 mb-3">
      <h5 class="d-inline">Tokens</h5>
      <span class="fs--1 ml-3">(Keep these secret! For localhost & backend servers only)</span>
    </div>

    <p v-for="token in paginatedTokens" class="text-monospace fs--1">
      <span v-if="token.name" class="font-weight-bold">{{ token.name }}</span>
      https://{{ token.value }}
      <b-button class="btn clipboard-btn" @click="$_copyToClipboard('https://' + token.value)">
        <b-icon icon="clipboard"></b-icon>
      </b-button>
    </p>

    <div v-if="countPages > 1">
      <b-pagination-nav
        base-url="#"
        v-model="currentPage"
        :number-of-pages="countPages"
        use-router
      />

      <p class="fs--1">{{ activeTokens.length }} Tokens</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rpc-card.card {
  .btn.clipboard-btn {
    padding: 5px;
    color: #5e6e82;
    border: none;
    background-color: inherit;
  }
}
</style>

<script>
import copyToClipboard from '@/mixins/copy-to-clipboard';
import _ from 'lodash';

export default {
  name: 'rpcTokens',

  mixins: [copyToClipboard],

  props: {
    subscriptions: { type: Array, default: () => [] },
  },

  data() {
    return {
      tokensPaginationData: [],
      currentPage: 1,
      perPage: 8,
      rpcExpanded: true,
    }
  },

  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },

    endIndex() {
      return this.startIndex + this.perPage;
    },
    hasActiveTokens() {
      return this.activeTokens.length > 0
    },

    activeTokens() {
      const formattedValues = [];

      this.subscriptions.forEach((subscription) => {
        subscription.endpoints.forEach((endpoint) => {
          if (endpoint.is_active) {
            endpoint.values.forEach((endpointValue) => {
              subscription.tokens.forEach((token) => {
                if (token.is_active) {
                  formattedValues.push({
                    name: token.name,
                    value: endpointValue + '/' + token.value
                  });
                }
              });
            });
          }
        });
      });

      return _.sortBy(formattedValues, 'name');
    },

    paginatedTokens() {
      return this.activeTokens.slice( this.startIndex, this.endIndex);
    },

    countPages() {
      return Math.ceil(this.activeTokens.length / this.perPage);
    },
  },
}
</script>
