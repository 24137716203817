import moment from 'moment';
import echart from '@/components/globals/echart';

const metricsMixin = {
  components: { echart },

  data() {
    return {

    }
  },

  methods: {
    now() {
      return moment().toDate();
    },

    thirtyMinutesAgo() {
      return moment().subtract(30, 'minutes').toDate();
    },

    oneHourAgo() {
      return moment().subtract(1, 'hours').toDate();
    },

    threeHoursAgo() {
      return moment().subtract(3, 'hours').toDate();
    },

    sixHoursAgo() {
      return moment().subtract(6, 'hours').toDate();
    },

    twelveHoursAgo() {
      return moment().subtract(12, 'hours').toDate();
    },

    beginningOfToday() {
      return moment().startOf('day').toDate();
    },

    beginningOfYesterday() {
      return moment().subtract(1, 'days').startOf('day').toDate();
    },

    endOfYesterday() {
      return moment().subtract(1, 'days').endOf('day').toDate();
    },

    sevenDaysAgo() {
      return moment().subtract(6, 'days').toDate();
    },
  }
};

export default metricsMixin;
