<template>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col-lg-6">
          <b-form v-on:submit.prevent="searchSubscriptions">
            <div class="form-group">
              <b-input v-model="searchSubscriptionsTerm" placeholder="Search by name" type="search" />
            </div>
          </b-form>
        </div>

        <div class="col-lg-6">
          <b-form-group>
            <v-select
              v-model="searchSubscriptionTypeName"
              :options="subscriptionTypesDropdownOptions"
              placeholder="Filter by subscription type"
              :clearable="true"
            />
          </b-form-group>
        </div>
        <div class="col-lg-12">
          <b-form-checkbox
            @change="setHideDisabledStatusAndSearch"
            v-model="hideDisabled"
            name="check-button"
            switch
            size="md"
            class="ml-1 mb-3">
            Hide disabled
          </b-form-checkbox>
        </div>
      </div>

      <div v-if="isLoading">
        <div class="text-center min-height mb-3" :style="`height: ${loadingHeight}px`">
          <b-spinner class="align-middle mr-3"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </div>
      <div v-else>
        <div class="table-responsive scrollbar min-height" ref="subscriptionsTable">
          <table v-if="meta?.total_count > 0" class="table table-hover table-sm">
            <thead>
              <tr class="btn-reveal-trigger">
                <th scope="col">
                  <div>Name</div>
                  <div v-if="currentUser.is_admin" class="border-top">Pool Name</div>
                </th>
                <th scope="col">Type</th>
                <th class="text-center">Tokens</th>
                <th class="text-center">Endpoints</th>
                <th scope="col" class="text-right">Status</th>
                <th v-if="currentUser.is_admin" class="text-right" scope="col"></th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="subscription in subscriptions" :key="subscription.uuid" class="btn-reveal-trigger">
              <td>
                <!-- Name -->
                <div>
                  <router-link :to="{ name: 'editSubscription', params: { uuid: subscription.uuid } }">
                    {{ subscription.name }}
                  </router-link>
                </div>
                <!-- Pool Name -->
                <div v-if="currentUser.is_admin && subscription.subscription_type?.pool_name" class="border-top">
                  {{ subscription.subscription_type?.pool_name }}
                </div>
              </td>
              <!-- Type -->
              <td>
                {{ subscription.subscription_type.display_name ? subscription.subscription_type.display_name : subscription.subscription_type.name }}
              </td>
              <!-- Tokens -->
              <td class="text-center">
                {{ subscription.tokens_count }}
              </td>
              <!-- Endpoints -->
              <td class="text-center">
                {{ subscription.endpoints_count }}
              </td>
              <td class="text-right">
                <span
                  v-if="subscription.is_active"
                  :class="isAccountActiveFor(subscription) ? 'text-success' : 'text-default'"
                  v-b-tooltip.hover="!isAccountActiveFor(subscription) ? 'Subscription is active but not usable because its Account is inactive' : ''"
                >
                  Enabled
                </span>
                <span v-else class="text-danger">
                  Disabled
                </span>
              </td>
              <td v-if="canManageSubscriptionActivation" class="text-right">
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret size="sm">
                  <template #button-content>
                    <b-icon-three-dots></b-icon-three-dots>
                  </template>

                  <b-dropdown-item @click="showSubscriptionActivationModal(subscription)">
                    <span v-if="subscription.is_active" class="text-danger">Deactivate</span>
                    <span v-else class="text-success">Activate</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="hasSubscriptionDeactivationReasons(subscription)"
                    v-b-modal="'deactivation-reasons-modal-' + subscription.uuid"
                  >
                    <span class="text-secondary">Deactivation reasons</span>
                  </b-dropdown-item>
                </b-dropdown>

                <activate-modal
                  resource-name="subscription"
                  :uuid="subscription.uuid"
                  v-on:activated="toggleSubscriptionActivation"
                />

                <deactivate-modal
                  resource-name="subscription"
                  :uuid="subscription.uuid"
                  v-on:deactivated="setSubscriptionDeactivationReason"
                />

                <deactivation-reasons-modal
                  title="Subscription"
                  :uuid="subscription.uuid"
                  :deactivationReasons="subscription.deactivation_reasons"
                  :deactivationReason="subscription.deactivationReason"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="meta?.total_pages > 1" class="overflow-auto">
        <b-pagination-nav
          :link-gen="linkGen"
          @change="changePage"
          v-model="currentPage"
          :number-of-pages="meta?.total_pages"
          use-router
        />
      </div>

      <p class="fs--1">{{ meta?.total_count }} Subscriptions</p>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import http from '@/services/http';
import events from '@/services/events';
import _ from 'lodash';
import debounce from 'lodash.debounce';

export default {
  name: 'SubscriptionsTable',

  data() {
    return {
      isLoading: false,
      loadingHeight: 0,
      hideDisabled: false,
      perPage: 50,
      currentPage: 1,
      searchBy: null,
      filterBy: null,
      subscriptions: [],
      searchSubscriptionsTerm: null,
      searchSubscriptionTypeName: null,
      meta: null
    }
  },

  props: {
    accountUuid: {
      type: String,
      required: false
    },

    userUuid: {
      type: String,
      required: false
    },

    isAccountActive: {
      type: Boolean,
      required: false
    },

    updatedAccount: {
      type: Object,
      required: false
    },
  },

  watch: {
    accountUuid: 'fetchSubscriptions',
    userUuid: 'fetchSubscriptions',
    searchSubscriptionsTerm: 'debouncedSearchSubscriptions',
    searchSubscriptionTypeName: 'searchSubscriptions',
    updatedAccount: 'updateSubscriptionsStatuses',
    subscriptionTypes: 'selectTypeAndFetchSubscriptions',
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser', 'canManageSubscriptionActivation'
    ]),
    ...mapState({
      subscriptionTypes: state => state.subscriptionTypes.subscriptionTypes,
    }),

    subscriptionTypesDropdownOptions() {
      return this.subscriptionTypes.map(st => (st.name));
    },

    findOptionByValue() {
      return this.subscriptionTypesDropdownOptions.find(option =>
        option === this.$route.query.subscriptions_subscription_type
      );
    },
  },

  methods: {
    async onCreated() {
      this.getCurrentPage();
      this.getHideDisabledStatus();
      await this.fetchSubscriptionTypes();
      await this.selectTypeAndFetchSubscriptions();
    },

    async selectTypeAndFetchSubscriptions() {
      if (this.subscriptionTypes.length > 0) {
        if (this.$route.query.subscriptions_subscription_type) {
          this.searchSubscriptionTypeName = this.findOptionByValue;
        } else {
          await this.fetchSubscriptions();
        }
      }
    },

    handleAccountEvent(data) {
      if (data.is_active) {
        this.subscriptions.forEach(subscription => {
          subscription.account.is_active = true;
          subscription.is_active = true;
        })
      } else {
        this.subscriptions.forEach(subscription => {
          subscription.account.is_active = false;
        })
      }
    },

    async fetchSubscriptionTypes() {
      if (this.subscriptionTypes.length === 0) {
        await this.$store.dispatch('subscriptionTypes/fetchSubscriptionTypes');
      }
    },

    getCurrentPage() {
      if (this.$route.query.subscriptions_page) {
        this.currentPage = parseInt(this.$route.query.subscriptions_page);
      }
    },

    getHideDisabledStatus() {
      const savedStatus = localStorage.getItem("hideDisabledSubscriptions");

      if (savedStatus) {
        this.hideDisabled = savedStatus === 'true';
      }
    },

    linkGen(pageNum) {
      let query = pageNum === 1 ? '?' : `?subscriptions_page=${pageNum}`;

      if (this.searchSubscriptionTypeName) {
        query += `&subscriptions_subscription_type=${this.searchSubscriptionTypeName}`;
      }

      return query;
    },

    showSubscriptionActivationModal(subscription) {
      if (subscription.is_active) {
        this.$bvModal.show(`deactivate-modal-${subscription.uuid}`);
      } else {
        this.$bvModal.show(`activate-modal-${subscription.uuid}`);
      }
    },

    toggleSubscriptionActivation(uuid) {
      const subscription = this.subscriptions.find(entry => entry.uuid === uuid);
      const i = this.subscriptions.indexOf(subscription);
      this.subscriptions[i].is_active = !this.subscriptions[i].is_active;
      this.$store.dispatch('subscriptions/toggleSubscriptionActivation', { subscriptionUuid: uuid })
    },

    setSubscriptionDeactivationReason(deactivationReason, uuid) {
      const subscription = this.subscriptions.find(entry => entry.uuid === uuid);
      const i = this.subscriptions.indexOf(subscription);
      this.subscriptions[i].deactivationReason = deactivationReason;

      this.toggleSubscriptionActivation(uuid);
    },

    hasSubscriptionDeactivationReasons(subscription) {
      return (subscription.deactivation_reasons
        && subscription.deactivation_reasons.length)
        || subscription.deactivationReason;
    },

    toggleSubscriptionState(subscription) {
      const uuids = this.subscriptions.map(s => s.uuid);
      const i = uuids.indexOf(subscription.uuid);
      this.subscriptions[i].is_active = !this.subscriptions[i].is_active;
    },

    debouncedSearchSubscriptions: debounce(function () {
      this.searchSubscriptions();
    }, 500),

    async changePage(newPage) {
      this.currentPage = newPage;

      await this.fetchSubscriptions();
    },

    async searchSubscriptions() {
      this.goToFirstPage();
      await this.fetchSubscriptions();
    },

    goToFirstPage() {
      let query = { subscriptions_page: this.currentPage = '1' };

      if (this.searchSubscriptionTypeName) {
        query.subscriptions_subscription_type = this.searchSubscriptionTypeName;
      }

      if (!_.isEqual(this.$route.query, query)) {
        this.$router.push({ name: this.$route.name, query: query });
      }
    },

    async fetchSubscriptions() {
      try {
        this.isLoading = true;
        this.loadingHeight = this.$refs.subscriptionsTable?.clientHeight;

        let subscriptionsParams = {
          page: this.currentPage,
          per: this.perPage
        }

        if (this.hideDisabled) {
          subscriptionsParams.is_active = true;
        }

        if (this.searchSubscriptionsTerm) {
          subscriptionsParams.name = this.searchSubscriptionsTerm
        }

        if (this.searchSubscriptionTypeName) {
          subscriptionsParams.subscription_type = this.searchSubscriptionTypeName
        }

        if (this.accountUuid) {
          subscriptionsParams.account_uuid = this.accountUuid
        } else if (this.userUuid) {
          subscriptionsParams.user_uuid = this.userUuid
        }

        const response = await http.get(`subscriptions`, {
          params: subscriptionsParams
        });

        this.subscriptions = response.data.subscriptions;
        this.meta = response.data.meta;

        if (this?.meta.total_pages > 0 && this.currentPage > this.meta?.total_pages) {
          await this.searchSubscriptions();
        }

        this.subscriptions.forEach(subscription => {
          events.$on(`accountEventReceived-${subscription.account.uuid}`, this.handleAccountEvent);
        });
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast('An error occurred while fetching subscriptions', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
      }

      finally {
        this.isLoading = false;
      }
    },

    setHideDisabledStatusAndSearch() {
      localStorage.setItem("hideDisabledSubscriptions", this.hideDisabled);

      this.searchSubscriptions();
    },

    isAccountActiveFor(subscription) {
      return this.isAccountActive ? true : subscription?.account && subscription.account.is_active;
    },

    updateSubscriptionsStatuses() {
      this.subscriptions.forEach(subscription => {
        if (subscription.account.uuid == this.updatedAccount.uuid) {
          subscription.account.is_active = this.updatedAccount.isActive;
        }
      })
    }
  }
}
</script>

<style scoped>
.min-height {
  min-height: 25px;
}
</style>
