<template>
  <div class="card mb-3">
    <b-form v-on:submit.prevent="updateSubscription">
      <div class="card-header bg-light pt-3 pb-3">
        <span v-if="formDisabled" class="mb-3 w-100">
          <span class="h2 flex-fill">
            {{ form.name }}
          </span>

          <span class="flex-fill float-right">
            <b-button
              v-if="canUpdateSubscriptions"
              class="btn btn-md"
              @click="enableForm"
            >
              Edit
              <span class="ml-2"></span>
            </b-button>
          </span>
        </span>
        <span v-else class="mb-3 w-100">
          <b-form-input
            v-model="form.name"
            placeholder="Display name"
            :state="nameState"
            class="full-width"
            name="name"
            size="lg"
          />
          <b-form-invalid-feedback :state="nameState">
            {{ nameErrors }}
          </b-form-invalid-feedback>
        </span>
      </div>
      <div v-if="!formDisabled || showStartsAtEndsAtDates" class="card-body">
        <div v-if="showStartsAtEndsAtDates" class="form-inline">
          <span class="flex-fill mr-3 min-height">
            <label class="justify-content-start">Starts at (In UTC)</label>
            <b-form-datepicker
              v-model="form.startsAt"
              :state="startsAtState"
              :disabled="formDisabled || !canUpdateDates"
              class="full-width"
              name="starts-at"
              today-button
              reset-button
              close-button
            />
            <b-form-invalid-feedback :state="startsAtState">
              {{ startsAtErrors }}
            </b-form-invalid-feedback>
          </span>
          <span class="flex-fill min-height">
            <label class="justify-content-start">Ends at (In UTC)</label>
            <b-form-datepicker
              v-model="form.endsAt"
              :state="endsAtState"
              :disabled="formDisabled || !canUpdateDates"
              class="full-width"
              name="ends-at"
              today-button
              reset-button
              close-button
            />
            <b-form-invalid-feedback :state="endsAtState">
              {{ endsAtErrors }}
            </b-form-invalid-feedback>
          </span>
        </div>

        <div v-if="!formDisabled" class="mt-3">
          <b-button type="submit" variant="primary">Save</b-button>
          <b-button variant="secondary" @click="undoFormChanges">
            Cancel
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

export default {
  name: 'UpdateSubscriptionForm',

  props: {
    subscription: { type: Object, required: true },
    account: { type: Object, required: true }
  },

  data() {
    return {
      form: {
        name: this.subscription.name,
        startsAt: this.subscription.starts_at,
        endsAt: this.subscription.ends_at
      },
      formDisabled: true,
      originalForm: {},
      errors: {}
    }
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser', 'canUpdateSubscriptions'
    ]),

    nameState() {
      return this.nameErrors ? false : null;
    },

    showStartsAtEndsAtDates() {
      return this.currentUser.is_admin || !this.paymentMethodIsHelio;
    },

    startsAtState() {
      return this.startsAtErrors ? false : null;
    },

    endsAtState() {
      return this.endsAtErrors ? false : null;
    },

    nameErrors() {
      const errors = this.errors['name'];
      if (errors) { return errors.join(', ') }
    },

    paymentMethodIsHelio() {
      return this.account.payment_method === 'helio';
    },

    startsAtErrors() {
      const errors = this.errors['starts_at'];
      if (errors) { return errors.join(', ') }
    },

    endsAtErrors() {
      const errors = this.errors['ends_at'];
      if (errors) { return errors.join(', ') }
    },

    canUpdateDates() {
      return this.currentUser.is_admin || this.currentUser.is_reseller;
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    onCreated() {
      this.setOriginalForm();
    },

    async updateSubscription() {
      try {
        await http.put(`subscriptions/${this.subscription.uuid}`, {
          subscription: {
            name: this.form.name,
            starts_at: this.form.startsAt,
            ends_at: this.form.endsAt
          }
        })

        this.errors = {};

        this.$bvToast.toast(`You successfully updated the subscription`, {
          title: 'Subscription updated',
          variant: 'default'
        });

        this.disableForm();
      }

      catch (error) {
        this.$bvToast.toast('Could not update subscription', {
          title: 'Update failed',
          variant: 'danger'
        });

        this.errors = error.data.errors;
      }
    },

    setOriginalForm() {
      const stringified = JSON.stringify(this.form);
      this.originalForm = JSON.parse(stringified);
    },

    undoFormChanges() {
      const stringified = JSON.stringify(this.originalForm);
      this.form = JSON.parse(stringified);
      this.disableForm();
    },

    enableForm() {
      this.formDisabled = false;
    },

    disableForm() {
      this.formDisabled = true;
      this.errors = {}
    },
  }
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
