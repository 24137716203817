<template>
  <div class="card">
    <div class="card-header">
      <span class="h3 float-start mr-2">Subscription Features</span>

      <span class="float-right">
        <b-button
          v-if="formDisabled && currentUser.is_admin"
          @click="enableSubscriptionForm"
          variant="secondary"
        >
          Edit
          <span class="ml-2 far fa-edit"></span>
        </b-button>
      </span>
    </div>

    <div class="card-body">
      <b-form v-on:submit.prevent="updateFeatures">
        <div v-if="isLoading">
          <div class="row">
            <div class="col-12">
              <div class="text-center my-4">
                <b-spinner class="align-middle mr-3"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <b-form-checkbox :disabled="formDisabled" size="lg" v-model="form.cascade_marketplace">
            Cascade marketplace
          </b-form-checkbox>
        </div>

        <div>
          <b-form-group label="Cascade marketplace rate limit">
            <div class="row">
              <div class="col-6 col-xs-12">
                <b-form-input :disabled="formDisabled" v-model="form.cascade_marketplace_rate_limit" />
              </div>
            </div>
          </b-form-group>
        </div>

        <div class="my-3" v-if="!formDisabled">
          <b-button type="submit" variant="primary">Save</b-button>

          <b-button variant="secondary" @click="undoFormChanges">
            Cancel
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      form: {
        cascade_marketplace: null,
        cascade_marketplace_rate_limit: null
      },
      formDisabled: true,
      originalForm: {},
      isLoading: false
    }
  },

  props: ['subscription'],

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', ['currentUser'])
  },

  methods: {
    async onCreated() {
      this.form.cascade_marketplace = this.subscription.cascade_marketplace;
      this.form.cascade_marketplace_rate_limit = this.subscription.cascade_marketplace_rate_limit;
      this.setOriginalForm();
    },

    async updateFeatures() {
      try {
        const response = await http.put(`/subscriptions/${this.subscription.uuid}/features`, { subscription: this.form });

        this.$bvToast.toast('Updated subscription', { title: 'Updated', variant: 'default' });

        this.setOriginalForm();
        this.disableSubscriptionForm();
      } catch (error) {
        console.log(error);
        this.$bvToast.toast('Could not update subscription', {
          title: 'Update failed',
          variant: 'danger'
        });
      }
    },

    undoFormChanges() {
      this.form = Object.assign({}, this.originalForm);
      this.disableSubscriptionForm();
    },

    enableSubscriptionForm() {
      this.formDisabled = false;
    },

    disableSubscriptionForm() {
      this.formDisabled = true;
    },

    setOriginalForm() {
      this.originalForm = Object.assign({}, this.form);
    }
  }
}
</script>

<style>
.btn-outline-secondary.disabled.active {
  color: #ffffff;
  background-color: #748194;
  border-color: #748194;
}
</style>