<template>
  <div class="py-4">
    <div v-if="isLoading" class="loading-overlay">
      <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" />
    </div>

    <div v-else class="container-xxl">
      <div class="mb-2 d-flex align-items-end">
        <div v-if="canManageAccountActivation">
          <activate-deactivate-switch
            :resource="account"
            resource-name="account"
            :is-admin-path="true"
            v-on:activated="activate"
            v-on:deactivated="deactivate"
          />
        </div>
        <div v-else>
          <h4>
            <b-badge v-if="isActive" variant="soft-success">Active</b-badge>
            <b-badge v-else variant="soft-danger">Inactive</b-badge>
          </h4>
        </div>

        <!-- Spacer to push the next element to the right -->
        <div class="ml-auto"></div>

        <div class="flex-item">
          <template v-if="currentUser?.is_admin">
            <a v-if="grafanaUrl" :href="grafanaUrl" target="_blank" class="btn btn-primary btn-sm mr-1">
              Grafana
            </a>

            <a v-if="account.communications_channel_url" :href="account.communications_channel_url" target="_blank" class="btn btn-primary btn-sm mr-1">
              Comm Channel
            </a>
          </template>

          <router-link v-if="isMetricsEnabled" :to="metricsUrl" class="btn btn-primary btn-sm">
            RPC Metrics
          </router-link>

          <router-link
            v-if="isMarketplaceEnabled || currentUser.is_admin"
            :to="{ name: 'marketplaceMetrics', params: { uuid: uuid } }"
            class="btn btn-primary btn-sm"
          >
            Cascade Marketplace Metrics
          </router-link>

          <a v-if="account.is_helio_pay_stream_active" href="https://hel.io" target="_blank" class="btn btn-primary">
            Manage Payment
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <deactivation-reasons-alert
            v-if="!isActive && hasDeactivationReasons"
            title="Account"
            :deactivation-reasons="account.deactivation_reasons"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6 col-lg-12">
          <div class="card mb-3">
            <div v-if="accountFormDisabled" class="card-header">
              <div>
                <span class="h2 float-start mr-2">{{ account.name }}</span>
                <span v-if="account.payment_method === 'helio'">
                  <a
                    v-if="account.is_helio_pay_stream_active"
                    v-b-tooltip.hover
                    title="Manage payment with Hel.io"
                    href="https://hel.io"
                    target="_blank"
                    class="badge rounded-capsule badge-soft-success"
                  >
                    Active Pay Stream
                  </a>
                  <a
                    v-else
                    v-b-tooltip.hover
                    title="Activate by submitting payment with Hel.io"
                    :href="helioPayStreamLink"
                    target="_blank"
                    class="badge rounded-capsule badge-soft-danger"
                  >
                    Inactive Pay Stream
                  </a>
                </span>

                <span class="float-right">
                  <b-button
                    v-if="accountFormDisabled"
                    variant="primary"
                    @click="enableAccountForm"
                  >
                    Edit
                    <span class="ml-2 far fa-edit"></span>
                  </b-button>
                </span>
              </div>

              <div v-if="currentUser?.is_admin" class="h6">
                <span class="mr-3">
                  <b-link v-b-modal="'versions-modal-' + account.uuid">
                    <span>Versions</span>
                  </b-link>

                  <versions-modal
                    :title="'Account: ' + account.name"
                    :uuid="account.uuid"
                    :versions="account.versions"
                  />
                </span>

                <b-link v-if="hasDeactivationReasons" v-b-modal="'deactivation-reasons-modal-' + uuid">
                  Deactivation reasons
                </b-link>

                <deactivation-reasons-modal
                  title="Account"
                  :uuid="uuid"
                  :deactivationReasons="account.deactivation_reasons"
                />
              </div>

            </div>
            <div class="card-body h-100">
              <div v-if="activeWithCoinflow && nextPaymentAt" class="row align-items-center mb-4">
                <div class="col">
                  <b-alert class="mb-0" variant="secondary" show>
                    Next Payment At: <strong>{{ nextPaymentAt }}</strong>
                  </b-alert>
                </div>
                <div class="col-auto">
                  <b-button @click="openCoinflowConfirmation">
                    Cancel
                    <cancel-coinflow-confirmation-modal
                      :uuid="uuid"
                      v-on:coinflow-subscription-cancelled="account.is_active = false"
                    />
                  </b-button>
                </div>
              </div>

              <account-form
                mode="update"
                :account="account"
                v-bind:accountFormDisabled="accountFormDisabled"
                v-on:disable-account-form="disableAccountForm"
                v-on:update-account-name="updateAccountName"
              />
            </div>
          </div>

          <account-features-card
            v-if="currentUser?.is_admin"
            :account="account"
            :users-for-account="usersForAccount"
            @metrics-enable-status="updateMetricsEnableStatus"
            class="mb-3"
          />
        </div>

        <div class="col-xl-6 col-lg-12">
          <div class="card mb-3">
            <div class="card-header">
              <span class="h2 float-left">Subscriptions</span>
              <span class="float-right">
                <b-button
                  v-if="canCreateSubscriptions"
                  v-b-modal="'modal-create-new-subscription'"
                  class="btn btn-primary"
                >
                  New

                  <create-new-subscription-modal :accounts="[account]" />
                </b-button>
              </span>
            </div>
            <div class="card-body h-100 pr-4">
              <subscriptions-table
                :accountUuid="uuid"
                :isAccountActive="isActive"
                @toggleSubscriptionActivation="fetchSubscriptions()"
              />
            </div>
          </div>

          <access-tokens-table
            v-if="canManageAccessTokens"
            :accountUuid="uuid"
            class="mb-3"
          />


          <div v-if="currentUser?.is_admin" class="mb-3">
            <div class="card h-lg-100 mb-3">
              <div class="card-header">
                <span class="h2 float-left">Users for account</span>
                <span class="float-right">
                  <b-button v-if="currentUser?.is_admin" variant="primary" v-b-modal.add-user-to-account>
                    Add & remove
                  </b-button>
                </span>

                <add-users-to-account-modal :account="account" :usersForAccount="usersForAccount" />
              </div>

              <div class="card-body h-100">
                <div class="table-responsive scrollbar">
                  <table class="table table-sm">
                    <thead class="border-bottom">
                      <tr>
                        <th scope="col">Username</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="user in usersForAccount" :key="user.uuid" class="align-middle">
                        <td>
                          <router-link
                            :to="{ name: 'manageUserRpc', params: { uuid: user.uuid } }"
                          >
                            {{ user.username }}
                          </router-link>
                        </td>
                        <td>
                          {{ user.email }}
                          <span v-if="user?.is_admin" class="badge badge-soft-primary float-right">
                            Admin
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6 col-lg-12">
          <helio-payments-card v-if="currentUser?.is_admin" />
        </div>

        <div class="col-xl-6 col-lg-12">
          <helio-webhook-events v-if="currentUser?.is_admin" />
        </div>

        <div class="col-xl-6 col-lg-12">
          <coinflow-webhook-requests-card v-if="currentUser.is_admin" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

import accountsMixin from '@/mixins/accounts';

// components
import cancelCoinflowConfirmationModal from './cancel-coinflow-confirmation-modal';
import addUsersToAccountModal from './add-users-to-account-modal';
import subscriptionsTable from '../../globals/subscriptions-table';
import createNewSubscriptionModal from '../../globals/create-new-subscription-modal';
import activateDeactivateSwitch from '../../globals/activate-deactivate-switch';
import versionsModal from '../../globals/versions-modal';
import deactivationReasonsAlert from '../../globals/deactivation-reasons-alert';
import helioWebhookEvents from './helio-webhook-events';
import helioPaymentsCard from './helio-payments-card';
import accessTokensTable from './access-tokens-table';
import accountFeaturesCard from './account-features-card';
import coinflowWebhookRequestsCard from './coinflow-webhook-requests-card';

export default {
  name: 'EditAccountView',

  components: {
    cancelCoinflowConfirmationModal,
    addUsersToAccountModal,
    subscriptionsTable,
    createNewSubscriptionModal,
    activateDeactivateSwitch,
    versionsModal,
    helioWebhookEvents,
    helioPaymentsCard,
    accessTokensTable,
    accountFeaturesCard,
    deactivationReasonsAlert,
    coinflowWebhookRequestsCard
  },

  mixins: [accountsMixin],

  data() {
    return {
      account: {},
      accountFormDisabled: true,
      usersForAccount: [],
      subscriptions: [],
      coinflowSubscription: {},
      isLoading: false
    }
  },

  watch: {
    $route(to, from) {
      if (to.params.uuid !== from.params.uuid) {
        this.onCreated();
      }
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters(
      'sessions', [
        'canManageAccountActivation',
        'canCreateSubscriptions',
        'currentUser',
        'helioPayStreamLink',
        'canManageAccessTokens',
        'getByUuid'
      ],
    ),

    isActive() {
      return this.account.is_active;
    },

    activeWithCoinflow() {
      return this.account.payment_method === 'coinflow' && this.isActive;
    },

    nextPaymentAt() {
      if (this.coinflowSubscription.nextPaymentAt) {
        return this.coinflowSubscription.nextPaymentAt.replace('T', ' ');
      }
    },

    uuid() {
      return this.$route.params.uuid;
    },

    metricsUrl() {
      return {
        path: `/accounts/${this.uuid}/metrics`
      };
    },

    isMetricsEnabled() {
      if (this.currentUser?.is_admin) {
        return true
      } else {
        return this.account.metrics_enabled && (this.hasActiveEndpoints || this.hasActiveTokens);
      }
    },

    isMarketplaceEnabled() {
      if (this.currentUser?.is_admin) {
        return true;
      } else {
        return this.account.cascade_marketplace;
      }
    },

    hasActiveEndpoints() {
      return this.subscriptions?.some(subscription =>
        subscription.endpoints?.some(endpoint => endpoint.is_active)
      ) || false;
    },

    hasActiveTokens() {
      return this.subscriptions?.some(subscription => subscription.tokens?.some(token => token.is_active)) || false;
    },

    hasDeactivationReasons() {
      return this.account.deactivation_reasons?.length;
    },

    grafanaUrl() {
      if (this.account.grafana_url) {
        if (this.account.grafana_url.includes('https://')) {
          return this.account.grafana_url;
        } else {
          return 'https://' + this.account.grafana_url;
        }
      }
    }
  },

  methods: {
    async onCreated() {
      try {
        this.isLoading = true;

        await this.fetchAccount();
        await this.fetchSubscriptions();
        await this.getUsersForAccount();

        if (this.activeWithCoinflow) { await this.fetchCoinflowSubscription(); }
      } catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      } finally {
        this.isLoading = false;
      }
    },

    enableAccountForm() {
      this.accountFormDisabled = false;
    },

    disableAccountForm() {
      this.accountFormDisabled = true;
    },

    updateAccountName(name) {
      this.account.name = name;
    },

    openCoinflowConfirmation() {
      this.$bvModal.show('cancel-coinflow-confirmation-modal');
    },

    async fetchAccount(forceReload = false) {
      const cachedAccount = this.getByUuid(this.uuid);
      if (!forceReload && cachedAccount) {
        this.account = JSON.parse(JSON.stringify(cachedAccount));
      } else {
        try {
          const response = await http.get(`accounts/${this.uuid}`);
          this.account = response.data.account;
        } catch (error) {
          if (error.status === 404) {
            this.$router.replace({
              name: 'notFound',
              params: { message: 'Account with given UUID cannot be found.' }
            });
          }
        }
      }
    },

    activate() {
      this.account.is_active = true;
    },

    deactivate(reason) {
      this.account.is_active = false;
      this.account.deactivation_reasons.push(reason);
    },

    async getUsersForAccount() {
      try {
        const response = await http.get(`users?account_uuid=${this.uuid}`);
        this.usersForAccount = response.data.users;
      }

      catch (error) {
        console.log(error);
      }
    },

    async fetchSubscriptions() {
      try {
        const response = await http.get(`subscriptions?account_uuid=${this.uuid}&is_active=true`);
        this.subscriptions = response.data.subscriptions;
      }

      catch (error) {
        console.log(error);
      }
    },

    async fetchCoinflowSubscription() {
      try {
        const response = await http.get(`accounts/${this.uuid}/coinflow/subscription`);
        this.coinflowSubscription = response.data.coinflow_subscription;
      }

      catch (error) {
        console.log(error);
      }
    },

    updateMetricsEnableStatus(status) {
      this.account.metrics_enabled = status;
    },
  }
}
</script>

<style lang="scss">

$active-color: #008000FF;
$inactive-color: #e63757;

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.metrics-link {
  display: flex;
  align-items: center;
  width: auto;
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  padding: 10px;
  margin-bottom: 7px;
  border-radius: 15px;
  transition: background-color 0.2s ease, transform 0.2s ease-in-out;
  border: 1px solid transparent;
}
.metrics-link:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.07);
}
.metrics-link:active {
  transform: scale(0.98);
  background: #e0e7f0;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.06), 0 0px 0px rgba(0, 0, 0, 0.08);
}

.switch {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $active-color;
    background-color: $active-color;
  }

  .custom-control-input:focus:checked ~ .custom-control-label::before {
    border-color: #377c5f;
    box-shadow: 0 0 0 0.2rem #9fd3bd;
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $inactive-color;
    box-shadow: 0 0 0 0.2rem #ffa2b3;
  }

  .custom-control-input:checked ~ .custom-control-label {
    color: $active-color;
  }

  .custom-control-input:not(:checked) ~ .custom-control-label {
    color: $inactive-color;
  }

  .custom-control-input:not(:checked) ~ .custom-control-label::before {
    border: $inactive-color solid 1px
  }

  .custom-control-input:not(:checked) ~ .custom-control-label::after {
    background-color: $inactive-color;
  }
}

</style>
