<template>
  <div class="p-3">
    <div v-if="isLoading" class="row">
      <div class="col-lg-12 col-md-12 mb-3">
        <div class="card">
          <div class="text-center my-4">
            <b-spinner class="align-middle mr-3"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-lg-3 col-md-12 mb-3">
        <div class="card">
          <ul class="list-unstyled nav flex-column m-0" id="search-nav">
            <div v-for="name in resultsCounts">
              <li
                :class="isComponentSelected(name) ? 'selected' : ''"
                class="nav-item"
              >
                <b-link @click="fetchVisibleComponent(name)" class="p-3">
                  {{ name }}
                  <span class="float-end indicator">
                    {{ searchResults.data.counts[name] }}
                  </span>
                </b-link>
              </li>
            </div>
          </ul>
        </div>
      </div>

      <div class="col-lg-9 col-md-12 mb-3">
        <div class="card">
          <div class="card-body">
            <h2 class="border-bottom mb-5">
              {{ resultsCountsTitle }}
            </h2>
            <div v-for="name in allowedResults">
              <component
                v-if="isComponentVisible(name)"
                :is="componentName(name)"
                :search-results="searchResults.data[name]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import http from '@/services/http';
import users from './users';
import accounts from './accounts';
import subscriptionTypes from './subscription-types';
import subscriptions from './subscriptions';
import endpoints from './endpoints';
import allowedOrigins from './allowed-origins';
import tokens from './tokens';
import rateTiers from './rate-tiers';

export default {
  name: 'NewSearchView',

  components: {
    users,
    accounts,
    subscriptions,
    subscriptionTypes,
    endpoints,
    allowedOrigins,
    tokens,
    rateTiers,
  },

  props: {},

  watch: {
    searchQuery: 'refreshSearchResults',
  },

  data() {
    return {
      query: this.$route.query.query,
      possibleResults: [
        'User',
        'Account',
        'Subscription',
        'SubscriptionType',
        'Endpoint',
        'AllowedOrigin',
        'Token',
        'RateTier',
      ],
      searchResults: null,
      selectedResult: null,
      isLoading: false,
    };
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapState({
      searchQuery: (state) => state.searchQuery,
    }),

    resultsCounts() {
      if (this.searchResults) {
        return Object.keys(this.searchResults.data.counts);
      }
    },

    allowedResults() {
      if (this.searchResults) {
        return this.possibleResults.filter((key) =>
          Object.keys(this.searchResults.data).includes(key)
        );
      }
    },

    resultsCountsTitle() {
      if (this.searchResults) {
        if (this.selectedResult) {
          const count = this.searchResults.data.counts[this.selectedResult];

          return `${count} ${this.selectedResult} Result${
            count > 1 ? 's' : ''
          }`;
        } else {
          const count = Object.values(this.searchResults.data.counts).reduce(
            (a, b) => a + b,
            0
          );

          return `${count} Result${count > 1 ? 's' : ''}`;
        }
      }
    },
  },

  methods: {
    async onCreated() {
      await this.fetchSearchResults();
    },

    refreshSearchResults() {
      this.query = this.searchQuery;

      this.fetchSearchResults();
    },

    async fetchSearchResults() {
      try {
        this.isLoading = true;

        this.searchResults = await http.get('admin/searches/new', {
          params: { 'search[query]': this.query },
        });

        this.isLoading = false;
      } catch (error) {
        this.$bvToast.toast(
          `Cannot load search results. Please try again later.`,
          {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 2000,
          }
        );
      }
    },

    isComponentSelected(name) {
      return this.selectedResult === name;
    },

    isComponentVisible(name) {
      return !this.selectedResult || this.isComponentSelected(name);
    },

    fetchVisibleComponent(name) {
      if (this.selectedResult === name) {
        this.selectedResult = null;
      } else {
        this.selectedResult = name;
      }
    },

    componentName(word) {
      let newWord = '';
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        if (char.match(/[A-Z]/)) {
          if (i > 1) {
            newWord += '-';
          }
          newWord += char.toLowerCase();
        } else {
          newWord += char;
        }
      }

      return newWord + 's';
    },
  },
};
</script>

<style scoped lang="scss">
.float-end {
  float: right;
}

.nav-item a {
  display: block;
  text-decoration: none;
  color: #5e6e82;

  span {
    color: white;
  }
}
.card {
  overflow: hidden;
}

.indicator {
  color: #5e6e82ad !important;
  font-weight: bold;
  font-size: 13px;
  padding: 2px 8px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.08);
}
li.selected .indicator {
  background: rgba(67, 39, 222, 0.7);
  border: 1px solid rgba(67, 39, 222, 0.8);
  box-shadow: 0 1px 0px rgba(67, 39, 222, 0.9);
  color: #ffffff !important;
}

li {
  border-bottom: 1px solid #e9e8e8;
  position: relative;
  border-left: 3px solid transparent;
  transition: border-color 0.3s ease;
}

li.selected {
  position: relative;
  font-weight: bold;
  border-left: 3px solid transparent;
}

li:hover {
  background-color: #f7f7f7;
}
li:active {
  background-color: #f1f1f1;
}

li.selected::before {
  content: '';
  position: absolute;
  left: -1%;
  top: 38%;
  bottom: 38%;
  width: 3px;
  background-color: #f606ff;
  box-shadow: 0 0 15px #f606ff, 0 0 5px #f606ff;
  border-radius: 1.5px;
}
</style>
