<template>
  <div class="container-xxl">
    <span v-if="subscription">
      <span class="h1 mr-3">
        {{ subscription.name }}
      </span>
      <router-link
        :to="{ name: 'editSubscription', params: { uuid: subscription.uuid } }"
      >
        Back to subscription
      </router-link>
    </span>
    <span v-else>
      <span class="h1 mr-3">
        {{ subscriptions[0]?.account?.name }}
      </span>
      <router-link
        :to="{ name: 'editAccount', params: { uuid: accountUuid } }"
      >
        Back to account
      </router-link>
    </span>

    <div class="card" v-if="isLoading">
      <div class="card-header text-center my-6">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading metric options...</strong>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-header text-center sticky-top">
        <div class="row">
          <div class="col">
            <div class="float-left">
              <div class="mb-1">
                {{ selectedRange }}
              </div>
              <div>
                <date-range-picker
                  time-picker
                  time-picker24-hour
                  auto-apply
                  opens="right"
                  :locale-data="{ format: 'yyyy/mm/dd HH:MM' }"
                  v-model="dateRange"
                  :ranges="dates"
                  append-to-body
                  @select="handleSelect"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mb-1">
              Auto Refresh
              <b-button
                v-if="selectedAutoRefresh === 'none'"
                class="ml-1"
                variant="outline-secondary"
                size="sm"
                @click="fetchMetrics"
              >
                <b-icon
                  icon="arrow-clockwise"
                  aria-hidden="true"
                  v-b-popover.hover.top="'Refresh now'"
                />
              </b-button>
            </div>
            <div>
              <b-form-radio-group
                v-model="selectedAutoRefresh"
                :options="autoRefreshOptions"
                buttons
                button-variant="outline-secondary"
                size="sm"
              />
            </div>
          </div>
          <div class="col">
            <div class="float-right">
              <div class="mb-1">Selected Resource</div>
              <div>
                <b-form-group>
                  <b-form-select v-model="selectedResource" :options="dropdownOptions" />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <b-alert
          dismissible
          fade
          :show="showAlert"
          @dismissed="showAlert=false"
          variant="danger"
          class="my-3"
        >
          There was an error fetching metrics
        </b-alert>
      </div>

      <div class="card-body">
        <echart chart-name="rps" :options="chartOptions" @fetch-error="handleFetchError" />

        <echart chart-name="rps-by-origin" :options="chartOptions" @fetch-error="handleFetchError" />

        <echart chart-name="traffic-in-mbits" :options="chartOptions"
                @fetch-error="handleFetchError"/>

        <echart chart-name="das-by-method" :options="chartOptions" @fetch-error="handleFetchError"/>

        <echart chart-name="rps-by-method" :options="chartOptions" @fetch-error="handleFetchError"/>

        <echart chart-name="rps-by-status" :options="chartOptions" @fetch-error="handleFetchError"/>

        <echart chart-name="traffic-by-method" :options="chartOptions"
                @fetch-error="handleFetchError"/>

        <echart
          chart-name="response-time-by-method"
          quantile="0.5"
          :options="chartOptions"
          @fetch-error="handleFetchError"
        />

        <echart
          chart-name="response-time-by-host"
          quantile="0.5"
          :options="chartOptions"
          @fetch-error="handleFetchError"
        />

        <echart
          chart-name="response-time-by-method"
          quantile="0.9"
          :options="chartOptions"
          @fetch-error="handleFetchError"
        />

        <echart
          chart-name="response-time-by-method"
          quantile="0.95"
          :options="chartOptions"
          @fetch-error="handleFetchError"
        />

        <echart chart-name="egress-bytes" :options="chartOptions" @fetch-error="handleFetchError"/>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import http from '@/services/http';
import { mapGetters } from 'vuex';
import echart from '@/components/globals/echart';
import moment from 'moment';

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    echart,
    DateRangePicker
  },

  name: 'AccountMetrics',

  data() {
    return {
      isLoading: false,
      dates: {
        'Last 30 Minutes': null,
        'Last 1 Hour': null,
        'Last 3 Hours': null,
        'Last 6 Hours': null,
        'Last 12 Hours': null,
        'Today': null,
        'Yesterday': null,
        'Last 7 Days': null
      },
      dateRange: {
        startDate: null,
        endDate: null
      },
      showAlert: false,
      selectedRange: 'Last 3 Hours',
      selectedResource: null,
      selectedAutoRefresh: 'none',
      autoRefreshOptions: [
        { text: 'None', value: 'none' },
        { text: '1 Min', value: '1m' },
        { text: '5 Min', value: '5m' },
        { text: '15 Min', value: '15m' },
      ],
      subscriptions: [],
      subscription: null,
      endpoints: [],
      tokens: [],
    }
  },

  created() {
    this.onCreated();
  },

  beforeDestroy() {
    this.clearInterval();
  },

  watch: {
    selectedAutoRefresh: function(newVal) {
      this.clearInterval(this.autoRefreshInterval);

      if (newVal !== 'none') {
        this.autoRefreshInterval = setInterval(this.fetchMetrics, parseInt(newVal) * 60 * 1000);
      }
    },

    selectedRange: function() {
      this.showAlert = false;
    },

    selectedResource: function() {
      this.showAlert = false;
    }
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser'
    ]),

    chartOptions() {
      return {
        accountUuid: this.accountUuid,
        subscriptionUuid: this.subscriptionUuid,
        endpointUuid: this.endpointUuid,
        tokenUuid: this.tokenUuid,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      }
    },

    subscriptionUuid() {
      if (this.selectedSubscriptionUuid) {
        return this.selectedSubscriptionUuid;
      } else if (this.selectedResource && this.selectedResource.name === 'subscription') {
        return this.selectedResource.uuid;
      }
    },

    endpointUuid() {
      if (this.selectedResource && this.selectedResource.name === 'endpoint') {
        return this.selectedResource.uuid;
      }
    },

    tokenUuid() {
      if (this.selectedResource && this.selectedResource.name === 'token') {
        return this.selectedResource.uuid;
      }
    },

    accountUuid() {
      return this.$route.params.uuid;
    },

    selectedSubscriptionUuid() {
      return this.$route.query.selected_subscription_uuid;
    },

    selectedEndpointUuid() {
      return this.$route.query.selected_endpoint_uuid;
    },

    selectedTokenUuid() {
      return this.$route.query.selected_token_uuid;
    },

    dropdownOptions() {
      return this.subscription
        ? this.singleSubscriptionDropdownOptions
        : this.multipleSubscriptionsDropdownOptions
    },

    singleSubscriptionDropdownOptions() {
      return [
        { value: null, text: 'All' },
        this.endpointsDropdownOptions(this.endpoints),
        this.tokensDropdownOptions(this.tokens),
      ].filter(option => option.text || option.options?.length > 0);
    },

    multipleSubscriptionsDropdownOptions() {
      const selectableSubscriptions = this.subscriptions.filter(s => this.isSubscriptionSelectable(s))

      let subscriptionOptions = selectableSubscriptions.flatMap((subscription) => [
        {
          text: 'Subscription: ' + subscription.name,
          value: { uuid: subscription.uuid, name: 'subscription' }
        },
        this.endpointsDropdownOptions(subscription.endpoints, subscription.name),
        this.tokensDropdownOptions(subscription.tokens, subscription.name)
      ]).filter(option => option.text || option.options?.length > 0);

      return [{ value: null, text: 'All' }, ...subscriptionOptions];
    },

    isCurrentUserAdmin() {
     return this.currentUser?.is_admin;
    },
  },

  methods: {
    async onCreated() {
      try {
        this.dateRange.startDate = this.threeHoursAgo();
        this.dateRange.endDate = this.now();

        this.setCalendarDates();

        this.selectedSubscriptionUuid
          ? await this.fetchSubscription()
          : await this.fetchSubscriptions();

        this.preselectResource();
        this.setCalendarDates();
      } finally {
        this.isLoading = false;
      }
    },

    async fetchSubscription() {
      try {
        const subscriptionResponse = await http.get(`subscriptions/${this.selectedSubscriptionUuid}`);
        this.subscription = subscriptionResponse.data.subscription;
        this.endpoints = this.subscription.endpoints;
        this.tokens = this.subscription.tokens;

        if (this.subscription.account?.uuid !== this.accountUuid) {
          this.showNotFoundPage('Account with given UUID cannot be found.');
        }
      }

      catch (error) {
        console.log(error);

        if (error.status === 404) {
          this.showNotFoundPage('Subscription with given UUID cannot be found.');
        }
      }
    },

    async fetchSubscriptions() {
      try {
        const response = await http.get(`subscriptions?account_uuid=${this.accountUuid}`);
        this.subscriptions = response.data.subscriptions;
      }

      catch (error) {
        console.log(error);

        if (error.status === 404) {
          this.showNotFoundPage('Subscriptions with given account UUID cannot be found.');
        }
      }
    },

    showNotFoundPage(message) {
      this.$router.push({
        name: 'notFound',
        params: { message: message }
      });
    },

    isSubscriptionSelectable(subscription) {
      if (this.isCurrentUserAdmin) {
        return true;
      } else {
        return this.hasActiveEndpoints(subscription) || this.hasActiveTokens(subscription);
      }
    },

    hasActiveEndpoints(subscription) {
      return subscription.endpoints.some(endpoint => endpoint.is_active);
    },

    hasActiveTokens(subscription) {
      return subscription.tokens.some(token => token.is_active);
    },

    preselectResource() {
      if (this.selectedEndpointUuid) {
        this.selectedResource = { name: 'endpoint', uuid: this.selectedEndpointUuid };
      } else if (this.selectedTokenUuid) {
        this.selectedResource = { name: 'token', uuid: this.selectedTokenUuid };
      }
    },

    endpointsDropdownOptions(endpoints, subscriptionName = null) {
      const options = endpoints.map((e) => {
        let text = e.values.join(', ');
        if (e.name) { text = `${text} (${e.name})`; }
        return { text: text, value: { uuid: e.uuid, name: 'endpoint' }, disabled: this.isDisabled(e) };
      });

      return {
        label: subscriptionName ? `Subscription: ${subscriptionName} endpoints` : 'endpoints',
        options: options
      }
    },

    tokensDropdownOptions(tokens, subscriptionName = null) {
      const options = tokens.map((t) => {
        let text = t.auth_username;
        if (t.name) { text = `${text} (${t.name})`; }
        return { text: text, value: { uuid: t.uuid, name: 'token' }, disabled: this.isDisabled(t) };
      });

      return {
        label: subscriptionName ? `Subscription: ${subscriptionName} tokens` : 'tokens',
        options: options
      }
    },

    isDisabled(object) {
      return !this.isCurrentUserAdmin && !object.is_active;
    },

    handleSelect(json) {
      const delta = json.endDate - json.startDate;
      for (const range in this.dates) {
        const [start, end] = this.dates[range];
        if (end - start === delta) {
          this.selectedRange = range;
          break;
        }

        this.selectedRange = 'Custom';
      }

      this.setCalendarDates();
    },

    setCalendarDates() {
      this.dates['Last 30 Minutes'] = [this.thirtyMinutesAgo(), this.now()]
      this.dates['Last 1 Hour'] = [this.oneHourAgo(), this.now()]
      this.dates['Last 3 Hours'] = [this.threeHoursAgo(), this.now()]
      this.dates['Last 6 Hours'] = [this.sixHoursAgo(), this.now()]
      this.dates['Last 12 Hours'] = [this.twelveHoursAgo(), this.now()]
      this.dates['Today'] = [this.beginningOfToday(), this.now()]
      this.dates['Yesterday'] = [this.beginningOfYesterday(), this.endOfYesterday()]
      this.dates['Last 7 Days'] = [this.sevenDaysAgo(), this.now()]
    },

    // this doesn't actually fetch the metrics but imposes changes on the child components
    // to make them fetch the metrics
    fetchMetrics() {
      this.showAlert = false;
      this.setCalendarDates();
      this.dateRange.startDate = this.dates[this.selectedRange][0];
      this.dateRange.endDate = this.dates[this.selectedRange][1];
    },

    handleFetchError(error) {
      if (!this.showAlert) {
        this.showAlert = true;

        // If we want, we could display the error message from BE response
        // if error.status == 422
        // this.alertMessage = error;
      }
    },

    clearInterval() {
      clearInterval(this.autoRefreshInterval);
    },

    now() {
      return moment().toDate();
    },

    thirtyMinutesAgo() {
      return moment().subtract(30, 'minutes').toDate();
    },

    oneHourAgo() {
      return moment().subtract(1, 'hours').toDate();
    },

    threeHoursAgo() {
      return moment().subtract(3, 'hours').toDate();
    },

    sixHoursAgo() {
      return moment().subtract(6, 'hours').toDate();
    },

    twelveHoursAgo() {
      return moment().subtract(12, 'hours').toDate();
    },

    beginningOfToday() {
      return moment().startOf('day').toDate();
    },

    beginningOfYesterday() {
      return moment().subtract(1, 'days').startOf('day').toDate();
    },

    endOfYesterday() {
      return moment().subtract(1, 'days').endOf('day').toDate();
    },

    sevenDaysAgo() {
      return moment().subtract(6, 'days').toDate();
    },
  }
}
</script>

<style scoped>
  .metrics {
    margin-bottom: 50px;
    width: 100%;
    height: 400px;
  }
</style>
