<template>
  <div class="row">
    <div class="col-12">
      <h3 class='border-bottom pb-1 mt-3 mb-0'>Subscriptions</h3>

      <table class="w-100 mb-3">
        <tbody>
          <tr v-for="subscription in searchResults" class='border-bottom'>
            <td class="text-break">
              <router-link :to="`/subscriptions/${subscription.uuid}`">
                {{ subscription.name }}
              </router-link>
            </td>
            <td class="text-break">
              belongs to:
              <router-link :to="`/accounts/${subscription.account_uuid}`">
                {{ subscription.account_name }}
              </router-link>
            </td>
            <td>
              Type: <span class="text-monospace">{{ subscriptionTypeName(subscription) }}</span>
            </td>
            <td>
              <span v-if="subscription.is_active" class="text-success">
                Enabled
              </span>
              <span v-else class="text-danger">
                Disabled
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionSearchResults',

  props: {
    searchResults: [],
  },

  methods: {
    subscriptionTypeName(subscription) {
      return subscription.subscription_type?.display_name || subscription.subscription_type?.name;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/custom/searches.scss';
</style>
