<template>
  <div>
    <div class="card" v-if="isLoading">
      <div class="card-header text-center my-6">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading metric options...</strong>
      </div>
    </div>
    <div v-else class="container-xl card">
      <div class="my-3">
        <router-link
          class="font-weight-normal"
          :to="{ name: 'editAccount', params: { uuid: accountUuid } }"
        >
          <b-icon scale="1.25" icon="arrow-left" class="mr-1 icon-hover"/>
          Account
        </router-link>
      </div>

      <div class="card-header text-center sticky-top">
        <div class="row">

          <!-- Datetimepicker -->
          <div class="col">
            <div class="float-left">
              <div class="mb-1">
                {{ selectedRange }}
              </div>
              <div>
                <date-range-picker
                  time-picker
                  time-picker24-hour
                  auto-apply
                  opens="right"
                  :locale-data="{ format: 'yyyy/mm/dd HH:MM' }"
                  v-model="dateRange"
                  :ranges="dates"
                  append-to-body
                  @select="handleSelect"
                />
              </div>
            </div>
          </div>

          <!-- Autorefresh selection -->
          <div class="col">
            <div class="mb-1">
              Auto Refresh
              <b-button
                v-if="selectedAutoRefresh === 'none'"
                class="ml-1"
                variant="outline-secondary"
                size="sm"
                @click="fetchMetrics"
              >
                <b-icon
                  icon="arrow-clockwise"
                  aria-hidden="true"
                  v-b-popover.hover.top="'Refresh now'"
                />
              </b-button>
            </div>
            <div>
              <b-form-radio-group
                v-model="selectedAutoRefresh"
                :options="autoRefreshOptions"
                buttons
                button-variant="outline-secondary"
                size="sm"
              />
            </div>
          </div>

          <!-- Subscription type selection -->
          <div class="col float-right">
            <div>
              <div class="mb-1">Selected resource</div>
              <div>
                <b-form-group>
                  <b-form-select v-model="selectedResource" :options="dropdownOptions"/>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <b-alert
          dismissible
          fade
          :show="showAlert"
          @dismissed="showAlert=false"
          variant="danger"
          class="my-3"
        >
          {{ alertMessage ? alertMessage : 'There was an error fetching metrics' }}
        </b-alert>
      </div>

      <template v-if="selectedResource">
        <div class="card-body">
          <echart chart-name="total-received-transactions" :options="chartOptions"
                  @fetch-error="handleFetchError"/>

          <!--          <echart chart-name="pool-landed-rate" :options="chartOptions"-->
          <!--                  @fetch-error="handleFetchError"/>-->

          <echart chart-name="landed-vs-not-landed" :options="chartOptions"
                  @fetch-error="handleFetchError"/>

          <!-- pie charts -->
          <div class="row">
            <div class="col-8">
              <span class="h4 border-bottom">Transaction Optimisation</span>
              <p class=mt-3>In order to get as good as possible transaction landing rate you want
                to:</p>
              <ul>
                <li>Submit transactions without requesting preflight</li>
                <li>Add priority fee and set compute budget</li>
                <li>Use the lowest possible compute budget</li>
                <li>Set priority fee to an appropriate value for your transactions</li>
              </ul>
              <p>
                For up to date docs, please check <a
                href="https://docs.triton.one/chains/solana/sending-txs">https://docs.triton.one/chains/solana/sending-txs</a>
              </p>
            </div>

            <!--            <div class="col-4">-->
            <!--              <h6>Priority fees (Pool)</h6>-->
            <!--            </div>-->
          </div>

        </div>
        <div class="row">
          <div class="col-4">
            <echart chart-name="transaction-optimisation-preflight" :options="chartOptions"
                    @fetch-error="handleFetchError" height="300px"/>
          </div>

          <div class="col-4">
            <echart chart-name="transaction-optimisation-priority-fees" :options="chartOptions"
                    @fetch-error="handleFetchError" height="300px"/>
          </div>

          <div class="col-4">
            <echart chart-name="transaction-optimisation-priority-fees-max-retries"
                    :options="chartOptions"
                    @fetch-error="handleFetchError" height="300px"/>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="v-chart-heading">Priority fees (Pool)</div>
                <echart chart-name="transaction-optimisation-priority-fees-pool-50"
                        :options="chartOptions"
                        @fetch-error="handleFetchError" height="300px"/>
              </div>
              <div class="col">
                <div>&nbsp;</div>
                <echart chart-name="transaction-optimisation-priority-fees-pool-90"
                        :options="chartOptions"
                        @fetch-error="handleFetchError" height="300px"/>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="v-chart-heading">Priority fees (Global)</div>
                <echart chart-name="transaction-optimisation-priority-fees-global-50"
                        :options="chartOptions"
                        @fetch-error="handleFetchError" height="300px"/>
              </div>
              <div class="col">
                <div>&nbsp;</div>
                <echart chart-name="transaction-optimisation-priority-fees-global-90"
                        :options="chartOptions"
                        @fetch-error="handleFetchError" height="300px"/>
              </div>
            </div>
          </div>
        </div>

        <echart chart-name="priority-fees-for-landed" :options="chartOptions"
                @fetch-error="handleFetchError"/>

        <div class="row">
          <div class="col">
            <echart chart-name="transaction-optimisation-priority-fees-submitted"
                    :options="chartOptions"
                    @fetch-error="handleFetchError"/>
          </div>

          <div class="col">
            <echart chart-name="transaction-optimisation-priority-fees-landed"
                    :options="chartOptions"
                    @fetch-error="handleFetchError"/>
          </div>
        </div>

        <echart chart-name="compute-budget-for-landed" :options="chartOptions"
                @fetch-error="handleFetchError"/>

        <div class="row">
          <div class="col">
            <echart chart-name="transaction-optimisation-compute-budget-submitted"
                    :options="chartOptions"
                    @fetch-error="handleFetchError"/>
          </div>

          <div class="col">
            <echart chart-name="transaction-optimisation-compute-budget-landed"
                    :options="chartOptions"
                    @fetch-error="handleFetchError"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'

import http from '@/services/http';
import metricsMixin from '@/mixins/metrics';

export default {
  components: {DateRangePicker},

  name: 'MarketplaceMetrics',

  mixins: [metricsMixin],

  data() {
    return {
      isLoading: false,
      dates: {
        'Last 30 Minutes': null,
        'Last 1 Hour': null,
        'Last 3 Hours': null,
        'Last 6 Hours': null,
        'Last 12 Hours': null,
        'Today': null,
        'Yesterday': null,
        'Last 7 Days': null
      },
      dateRange: {
        startDate: null,
        endDate: null
      },
      showAlert: false,
      alertMessage: null,
      selectedRange: 'Last 3 Hours',
      selectedAutoRefresh: 'none',
      selectedResource: null,
      dropdownOptions: [],
      subscriptionTypes: [],
      subscriptions: [],
      autoRefreshOptions: [
        {text: 'None', value: 'none'},
        {text: '1 Min', value: '1m'},
        {text: '5 Min', value: '5m'},
        {text: '15 Min', value: '15m'},
      ],
    }
  },

  created() {
    this.onCreated();
  },

  beforeDestroy() {
    this.clearInterval();
  },

  watch: {
    $route(to, from) {
      if (to.params.uuid !== from.params.uuid) {
        this.onCreated();
      }
    },

    selectedAutoRefresh: function (newVal) {
      this.clearInterval(this.autoRefreshInterval);

      if (newVal !== 'none') {
        this.autoRefreshInterval = setInterval(this.fetchMetrics, parseInt(newVal) * 60 * 1000);
      }
    },

    selectedRange: function () {
      this.showAlert = false;
    },

    selectedResource: function () {
      this.showAlert = false;
    }
  },

  computed: {
    chartOptions() {
      const options = {
        accountUuid: this.$route.params.uuid,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      }

      if (this.selectedResource) {
        const subTypeUuid = this.subscriptionTypes.find(st => st.uuid === this.selectedResource)?.uuid;
        if (subTypeUuid) {
          options.subscriptionTypeUuid = subTypeUuid;
        }

        const subUuid = this.subscriptions.find(sub => sub.uuid === this.selectedResource)?.uuid;
        if (subUuid) {
          options.subscriptionUuid = subUuid;
        }
      }
      return options;
    },

    accountUuid() {
      return this.$route.params.uuid;
    },

    marketplaceSubscriptions() {
      return this.subscriptions.filter(sub => sub.cascade_marketplace);
    }
  },

  methods: {
    async onCreated() {
      try {
        this.isLoading = true;

        await this.loadSubscriptionTypesAndSubscriptions();

        this.loadDropdownOptions();

        this.dateRange.startDate = this.threeHoursAgo();
        this.dateRange.endDate = this.now();

        this.setCalendarDates();

        if (this.subscriptionTypes.length === 0) {
          this.showAlert = true;
          this.alertMessage = 'This account has no subscription types with a pool name';
        }
      } finally {
        this.isLoading = false;
      }
    },

    async loadSubscriptionTypesAndSubscriptions() {
      const response = await http.get(`subscriptions?account_uuid=${this.accountUuid}`);
      this.subscriptions = response.data.subscriptions;
      this.subscriptionTypes = this.subscriptions.map(s => s.subscription_type).filter(st => st.pool_name);
    },

    loadDropdownOptions() {
      const subscriptionTypesOptions = [];
      const subscriptionsOptions = [];

      // Show disabled for the devnet testnet developer sub types name
      for (let subscription of this.subscriptions) {
        if (['shared', 'developer', 'devnet', 'testnet'].some(substring => subscription.subscription_type.name.includes(substring))) {
          // if shared pool, show subscriptions dropdown
          // if it's developer, devnet, testnet it's disabled
          // Show in parens the sub type name - only affects liquids drops
          const option = {
            text: `Subscription: ${subscription.name} (${subscription.subscription_type.name})`,
            value: subscription.uuid
          };
          if (!subscription.subscription_type.name.includes('shared')) option.disabled = true;
          subscriptionsOptions.push(option);
        } else {
          // if dedi, show subscription types as dropdown option
          subscriptionTypesOptions.push({
            text: `Subscription Type: ${subscription.subscription_type.name}`,
            value: subscription.subscription_type.uuid
          });
        }
      }

      this.dropdownOptions = subscriptionsOptions.concat(subscriptionTypesOptions);
      this.selectedResource = this.dropdownOptions[0]?.value;
    },

    handleSelect(json) {
      const delta = json.endDate - json.startDate;
      for (const range in this.dates) {
        const [start, end] = this.dates[range];
        if (end - start === delta) {
          this.selectedRange = range;
          break;
        }

        this.selectedRange = 'Custom';
      }

      this.setCalendarDates();
    },

    setCalendarDates() {
      this.dates['Last 30 Minutes'] = [this.thirtyMinutesAgo(), this.now()]
      this.dates['Last 1 Hour'] = [this.oneHourAgo(), this.now()]
      this.dates['Last 3 Hours'] = [this.threeHoursAgo(), this.now()]
      this.dates['Last 6 Hours'] = [this.sixHoursAgo(), this.now()]
      this.dates['Last 12 Hours'] = [this.twelveHoursAgo(), this.now()]
      this.dates['Today'] = [this.beginningOfToday(), this.now()]
      this.dates['Yesterday'] = [this.beginningOfYesterday(), this.endOfYesterday()]
      this.dates['Last 7 Days'] = [this.sevenDaysAgo(), this.now()]
    },

    // this doesn't actually fetch the metrics but imposes changes on the child components
    // to make them fetch the metrics
    fetchMetrics() {
      this.showAlert = false;
      this.setCalendarDates();
      this.dateRange.startDate = this.dates[this.selectedRange][0];
      this.dateRange.endDate = this.dates[this.selectedRange][1];
    },

    handleFetchError(error) {
      if (!this.showAlert) {
        this.showAlert = true;
        this.alertMessage = 'There was an error fetching metrics';

        // If we want, we could display the error message from BE response
        // if error.status == 422
        // this.alertMessage = error;
      }
    },

    clearInterval() {
      clearInterval(this.autoRefreshInterval);
    },
  }
};
</script>
<style scoped>
.v-chart-heading {
  font-weight: bold !important;
  font: 17px sans-serif;
  color: #464646;
}
</style>
