<template>
  <b-form v-on:submit.prevent="createOrUpdateAccount">
    <div class="row">
      <div v-if="createMode || !accountFormDisabled" :class="createMode ?' col-8' : 'col-12'">
        <b-form-group :label="createMode ? 'Name' : ''" label-for="name">
          <b-form-input
            id="name"
            v-model="form.name"
            :state="inputState('name')"
            :disabled="accountFormDisabled"
            autofocus
          />
          <b-form-invalid-feedback :state="inputState('name')">
            {{ inputError('name') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div :class="createMode ?' col-4' : 'col-12'">
        <b-form-group label="Country code" label-for="billing_country_code">
          <b-form-select
            id="billing_country_code"
            v-model="form.billing_country_code"
            :state="inputState('billing_country_code')"
            :options="countryCodesDropdownOptions"
            :disabled="accountFormDisabled"
          />
          <b-form-invalid-feedback :state="inputState('billing_country_code')">
            {{ inputError('billing_country_code') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <b-form-group v-if="createMode" label="Payment method" label-class="h4" :disabled="accountFormDisabled">
          <b-form-radio-group
            v-model="form.payment_method"
            :state="inputState('payment_method')"
            :options="paymentMethodOptions"
            button-variant="outline-secondary"
            :class="accountFormDisabled ? 'disableable-radio' : ''"
            buttons
          />
          <b-form-invalid-feedback :state="inputState('payment_method')">
            {{ inputError('payment_method') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <div class="mb-3">
      <b-button variant="outline-secondary" size="sm" v-b-toggle.collapse-address @click="toggleAddress">
        Address
        <b-icon class="b-icon" v-if="isAddressHidden" icon="chevron-down" />
        <b-icon class="b-icon" v-else icon="chevron-up" />
      </b-button>
    </div>

    <b-collapse id="collapse-address">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <b-form-group label="Address Line 1" label-for="billing_address1">
            <b-form-input
              id="billing_address1"
              v-model="form.billing_address1"
              :state="inputState('billing_address1')"
              :disabled="accountFormDisabled"
            />
            <b-form-invalid-feedback :state="inputState('billing_address1')">
              {{ inputError('billing_address1') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6 col-sm-12">
          <b-form-group label="Address Line 2" label-for="billing_address2">
            <b-form-input
              id="billing_address2"
              v-model="form.billing_address2"
              :state="inputState('billing_address2')"
              :disabled="accountFormDisabled"
            >
            </b-form-input>
            <b-form-invalid-feedback :state="inputState('billing_address2')">
              {{ inputError('billing_address2') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6 col-sm-12">
          <b-form-group label="City" label-for="billing_city">
            <b-form-input
              id="billing_city"
              v-model="form.billing_city"
              :state="inputState('billing_city')"
              :disabled="accountFormDisabled"
            />
            <b-form-invalid-feedback :state="inputState('billing_city')">
              {{ inputError('billing_city') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-md-6 col-sm-12">
          <b-form-group label="Address Line 3" label-for="billing_address3">
            <b-form-input
              id="billing_address3"
              v-model="form.billing_address3"
              :state="inputState('billing_address3')"
              :disabled="accountFormDisabled"
            />
            <b-form-invalid-feedback :state="inputState('billing_address3')">
              {{ inputError('billing_address3') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <b-form-group label="State or Province" label-for="billing_state_or_province">
            <b-form-input
              id="billing_state_or_province"
              v-model="form.billing_state_or_province"
              :state="inputState('billing_state_or_province')"
              :disabled="accountFormDisabled"
            />
            <b-form-invalid-feedback :state="inputState('billing_state_or_province')">
              {{ inputError('billing_state_or_province') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-6">
          <b-form-group label="Postal Code" label-for="billing_postal_code">
            <b-form-input
              id="billing_postal_code"
              v-model="form.billing_postal_code"
              :state="inputState('billing_postal_code')"
              :disabled="accountFormDisabled"
            />
            <b-form-invalid-feedback :state="inputState('billing_postal_code')">
              {{ inputError('billing_postal_code') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
    </b-collapse>

    <div class="mb-3 pt-2">
      <b-button variant="outline-secondary" size="sm" v-b-toggle.collapse-telephone @click="toggleTelephone">
        Telephone
        <b-icon class="b-icon" v-if="isTelephoneHidden" icon="chevron-down" />
        <b-icon class="b-icon" v-else icon="chevron-up" />
      </b-button>
    </div>

    <b-collapse id="collapse-telephone">
      <div class="row">
        <div class="col-3">
          <b-form-group label="Country code" label-for="telephone_country_code">
            <b-form-select
              id="telephone_country_code"
              v-model="form.telephone_country_code"
              :state="inputState('telephone_country_code')"
              :options="telephoneCountryCodeDropdownOptions"
              :disabled="accountFormDisabled"
            />
            <b-form-invalid-feedback :state="inputState('telephone_country_code')">
              {{ inputError('telephone_country_code') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="col-9">
          <b-form-group label="Number" label-for="telephone">
            <b-form-input
              v-model="form.telephone"
              :state="inputState('telephone')"
              :disabled="accountFormDisabled"
              id="telephone"
            />
            <b-form-invalid-feedback :state="inputState('telephone')">
              {{ inputError('telephone') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
    </b-collapse>

    <template v-if="currentUser.is_admin">
      <b-form-group label="Grafana URL" label-for="grafana_url">
        <b-form-input
          v-model="form.grafana_url"
          :state="inputState('grafana_url')"
          :disabled="accountFormDisabled"
          id="grafana_url"
        />
        <b-form-invalid-feedback :state="inputState('grafana_url')">
          {{ inputError('grafana_url') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Communications channel URL" label-for="communications_channel_url">
        <b-form-input
          v-model="form.communications_channel_url"
          :state="inputState('communications_channel_url')"
          :disabled="accountFormDisabled"
          id="communications_channel_url"
        />
        <b-form-invalid-feedback :state="inputState('communications_channel_url')">
          {{ inputError('communications_channel_url') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Administrator notes" label-for="administrator_notes">
        <b-form-textarea
          id="administrator_notes"
          v-model="form.administrator_notes"
          :state="inputState('administrator_notes')"
          :disabled="accountFormDisabled"
          rows="3"
        />
        <b-form-invalid-feedback :state="inputState('administrator_notes')">
          {{ inputError('administrator_notes') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </template>

    <div class="my-3" v-if="!accountFormDisabled">
      <b-button type="submit" variant="primary">Save</b-button>

      <b-button v-if="mode === 'update'" variant="secondary" @click="undoFormChanges">
        Cancel
      </b-button>
      <span v-else>
        <b-button v-if="mode === 'update'" variant="secondary" @click="navigateBack">
          Cancel
        </b-button>
        <b-button v-if="mode === 'create'" variant="secondary" @click="closeCreateAccountModal">
          Cancel
        </b-button>
      </span>
    </div>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import http from '@/services/http';
import countryCodes from '@/constants/accounts.js';

export default {
  props: {
    mode: {
      type: String,
      required: true
    },

    accountFormDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    account: {
      type: Object,
      required: false
    },

    userUuid: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      uuid: null,
      form: {
        name: null,
        billing_address1: null,
        billing_address2: null,
        billing_address3: null,
        billing_city: null,
        billing_state_or_province: null,
        billing_postal_code: null,
        billing_country_code: null,
        telephone_country_code: null,
        telephone: null,
        payment_method: null,
        grafana_url: null,
        communications_channel_url: null
      },
      originalForm: {},
      paymentMethodOptions: [
        { value: 'manual', text: 'Manual' },
        { value: 'helio', text: 'Hel.io' }
      ],
      errors: {},
      isAddressHidden: true,
      isTelephoneHidden: true,
      countryCodes
    };
  },

  created() {
    if (!this.createMode) {
      this.form = JSON.parse(JSON.stringify(this.account));
      this.uuid = this.form.uuid;
    }

    this.setOriginalForm();
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser'
    ]),

    countryCodesDropdownOptions() {
      let options = this.countryCodes.map((c) => (
        { value: c.abbreviation, text: `${c.flagEmoji} ${c.country} (${c.abbreviation})` }
      ));

      options[0] = { value: null, text: '' }
      return options;
    },

    telephoneCountryCodeDropdownOptions() {
      let options = this.countryCodes.map((c) => (
        { value: c.telephoneCode, text: `${c.flagEmoji} ${c.country} (${c.telephoneCode})` }
      ));

      options[0] = { value: null, text: '' }
      return options;
    },

    accountParams() {
      let baseParams = {
        name: this.form.name,
        billing_address1: this.form.billing_address1,
        billing_address2: this.form.billing_address2,
        billing_address3: this.form.billing_address3,
        billing_city: this.form.billing_city,
        billing_state_or_province: this.form.billing_state_or_province,
        billing_postal_code: this.form.billing_postal_code,
        billing_country_code: this.form.billing_country_code,
        telephone_country_code: this.form.telephone_country_code,
        telephone: this.form.telephone
      }

      if (this.mode === 'create') {
        baseParams.payment_method = this.form.payment_method;
      }

      if (this.currentUser.is_admin) {
        baseParams.administrator_notes = this.form.administrator_notes;
        baseParams.grafana_url = this.form.grafana_url;
        baseParams.communications_channel_url = this.form.communications_channel_url;
      }

      return { account: baseParams };
    },

    createMode() {
      return this.mode === 'create';
    }
  },

  methods: {
    createOrUpdateAccount() {
      if (!this.createMode) {
        this.updateAccount();
      } else {
        this.createAccount();
      }
    },

    undoFormChanges() {
      this.form = Object.assign({}, this.originalForm);
      this.errors = {}
      this.$emit('disable-account-form');
    },

    async updateAccount() {
      try {
        await http.put(`accounts/${this.uuid}`, this.accountParams);

        this.setOriginalForm();
        this.errors = {}
        this.$emit('disable-account-form');
        this.$emit('update-account-name', this.form.name);

        this.$root.$bvToast.toast('Updated account', {
          title: 'Account updated',
          variant: 'default'
        });
      }

      catch (error) {
        console.log('Error:', error)
        this.errors = error.data.errors;
      }
    },

    async createAccount() {
      try {
        const params = this.accountParams;

        if (this.userUuid) {
          params['account']['user_uuid'] = this.userUuid;
        }

        const response = await http.post('accounts', params);

        this.$root.$bvToast.toast('Created account', {
          title: 'Account created',
          variant: 'default'
        });

        this.$router.push({
          name: 'editAccount',
          params: { uuid: response.data.account.uuid }
        });
      }

      catch (error) {
        console.log('Error:', error)
        this.errors = error.data.errors;
      }
    },

    navigateBack() {
      this.$router.go(-1);
    },

    closeCreateAccountModal() {
      this.$bvModal.hide('modal-create-new-account');
    },

    toggleAddress() {
      this.isAddressHidden = !this.isAddressHidden;
    },

    toggleTelephone() {
      this.isTelephoneHidden = !this.isTelephoneHidden;
    },

    inputState(inputName) {
      if (this.errors[inputName]) { return false; }
    },

    inputError(inputName) {
      const errors = this.errors[inputName];
      if (errors) { return errors.join(', ') }
    },

    setOriginalForm() {
      this.originalForm = Object.assign({}, this.form);
    }
  }
}
</script>

<style scoped>
.disableable-radio {
  pointer-events: none;
}

.b-icon {
  position: relative;
  top: 2px;
}
</style>
